import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { ContentRoute, LayoutSplashScreen } from "../_metronic/layout";
import DashboardPage from "./pages/DashboardPage";
import { getUserInfo } from "../utils/user.util";
import Profile from "../_metronic/components/SuperAdmin/Profile/Profile.js";
import News from "../_metronic/components/News/News.js";
import stepper from "../_metronic/components/Stepper/Stepper.jsx";
import Kontaktdetails from "../_metronic/components/Kontaktdetails/Kontaktdetails.js";
import Anfragedetails from "../_metronic/components/AnfrageDetails/Anfragedetails.jsx";
import FunnelResponse2 from "../_metronic/components/FunnelResponse2/FunnelResponse2.jsx";
import CredentialManagement from "../_metronic/components/CredentialManagement/CredentialManagement.jsx";
import Project from "../_metronic/components/ProjectName/Project.jsx";
import Adminmitteilungen from "../_metronic/components/mitteilungenAdmin/index.jsx";
import VattenfallLeads from "../_metronic/components/vattenfallLeads/index.jsx";
import VattenfallLeadsMesse from "../_metronic/components/vattenfallMesse/index.jsx";
import VattenfallPromoLeads from "../_metronic/components/vattenfallPromo/index.jsx";

export default function BasePage() {
  let userInfo = getUserInfo();
  return (
    <>
      {userInfo.role === "admin" ? (
        <Suspense fallback={<LayoutSplashScreen />}>
          <Switch>
            <Redirect exact from="/" to="/dashboard" />
            <ContentRoute exact path="/dashboard" component={DashboardPage} />
            <ContentRoute exact path="/profile" component={Profile} />
            <ContentRoute exact path="/anfrange" component={Anfragedetails} />
            <ContentRoute exact path="/stepper" component={stepper} />
            <ContentRoute exact path="/news" component={News} />
            <ContentRoute exact path="/kontakt" component={Kontaktdetails} />
            <ContentRoute exact path="/funnel2" component={FunnelResponse2} />
            <ContentRoute
              exact
              path="/mitteilungen"
              component={Adminmitteilungen}
            />
            <ContentRoute
              exact
              path="/vattenfall"
              component={VattenfallLeads}
            />
            <ContentRoute
              exact
              path="/vattenfallmesse"
              component={VattenfallLeadsMesse}
            />
            <ContentRoute
              exact
              path="/vattenfallpromo"
              component={VattenfallPromoLeads}
            />
            <ContentRoute
              exact
              path="/credential"
              component={CredentialManagement}
            />
            <ContentRoute exact path="/project" component={Project} />
          </Switch>
        </Suspense>
      ) : userInfo.role === "vattenfallAdmin" ? (
        <Suspense fallback={<LayoutSplashScreen />}>
          <Switch>
            <Redirect exact from="/" to="/dashboard" />
            <ContentRoute exact path="/dashboard" component={DashboardPage} />
            {/* <ContentRoute exact path="/profile" component={Profile} /> */}
            {/* <ContentRoute exact path="/anfrange" component={Anfragedetails} />
          <ContentRoute exact path="/stepper" component={stepper} />
          <ContentRoute exact path="/news" component={News} />
          <ContentRoute exact path="/kontakt" component={Kontaktdetails} />
          <ContentRoute exact path="/funnel2" component={FunnelResponse2} />
          <ContentRoute exact path="/mitteilungen" component={Adminmitteilungen} /> */}
            <ContentRoute
              exact
              path="/vattenfall"
              component={VattenfallLeads}
            />
            <ContentRoute
              exact
              path="/vattenfallmesse"
              component={VattenfallLeadsMesse}
            />
            <ContentRoute
              exact
              path="/vattenfallpromo"
              component={VattenfallPromoLeads}
            />
            <ContentRoute
              exact
              path="/credential"
              component={CredentialManagement}
            />
            <ContentRoute exact path="/project" component={Project} />
          </Switch>
        </Suspense>
      ) : (
        <Suspense fallback={<LayoutSplashScreen />}>
          <Switch>
            <Redirect to="error/error-v6" />
          </Switch>
        </Suspense>
      )}
    </>
  );
}
