import React, { useEffect, useState } from "react";
import CountUp from "react-countup";

import { ApiGet } from "../../../helpers/API/ApiData";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import { getUserInfo } from "../../../utils/user.util";

export function Demo1Dashboard() {
  const [dashboardCount, setDashboardCount] = useState({});
  const [id, setId] = useState("");

  useEffect(() => {
    handleGetDashboardCount();
  }, []);
  const handleGetDashboardCount = async () => {
    await ApiGet(`user/dashboard`)
      .then((response) => {
        const data = response?.data?.payload;

        console.log("data", data);
        setDashboardCount(data);
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  let userInfo = getUserInfo();
  return (
    <>
      <div className=" ">
        {userInfo.role === "admin" ? (
          <>
            <div className="col-lg-12 col-xxl-12 p-0">
              <div className="row">
                <div
                  className="col-xl-3 col-md-6 mb-4"
                  onMouseEnter={() => setId(1)}
                  onMouseLeave={() => setId("")}
                >
                  <NavLink className="" to="/stepper">
                    <div className="card rounded-3 border-5 border-bottom shadow h-100 py-2">
                      <div className="card-body cursor-pointer">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="text-xs font-weight-bold text-sjma text-uppercase mb-1">
                              WattFox Leads
                            </div>
                            <div className="h5 mb-0 font-weight-bold text-danger-800">
                              <CountUp end={dashboardCount.staperCount || 0} />
                            </div>
                          </div>
                          <div className="col-auto">
                            {id == "1" ? (
                              <svg
                                width="27"
                                height="27"
                                viewBox="0 0 270 228"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M52.7207 227.986C51.2107 226.076 48.7207 226.156 46.7207 225.136C40.431 221.967 35.5933 216.51 33.2007 209.886C31.8364 206.193 31.1586 202.282 31.2007 198.346C31.2407 174.106 31.2407 149.859 31.2007 125.606C31.3613 124.424 31.2096 123.221 30.7607 122.116H20.5507C16.8907 122.116 13.2207 122.116 9.5507 122.056C4.6907 121.986 0.640703 117.786 0.100703 112.986C-0.459297 107.986 1.3107 104.486 5.5507 101.696C8.8807 99.5158 11.6907 96.5658 14.7807 94.0058C23.694 86.6591 32.6174 79.3191 41.5507 71.9858C41.9107 71.6858 42.2907 71.3958 42.7207 71.0558C43.086 71.2218 43.4022 71.4794 43.6387 71.8035C43.8752 72.1276 44.024 72.5073 44.0707 72.9058C44.3107 77.4358 45.8007 81.7258 46.3707 86.2058C46.8607 90.0458 48.1107 93.7858 48.9607 97.5858C49.3807 99.4958 49.6907 101.446 49.9607 103.356C51.1207 110.356 52.9607 117.216 54.3807 124.146C55.5607 129.776 56.8207 135.416 58.3807 140.956C59.9407 146.496 61.4607 152.186 62.9507 157.806C64.3807 163.206 65.8107 168.626 67.4307 173.956C69.5107 180.796 71.3107 187.746 73.9907 194.416C75.0407 197.036 75.6607 199.826 76.5607 202.796H97.8907C111.001 169.616 124.001 136.346 134.781 102.086C145.521 136.206 158.511 169.456 171.701 202.866H192.761C207.851 159.776 217.571 115.516 226.151 70.4258L243.771 84.9858C251.001 90.9858 258.151 97.0658 265.511 102.886C266.766 103.888 267.806 105.133 268.568 106.548C269.329 107.962 269.796 109.516 269.941 111.116C269.161 113.836 268.941 117.026 266.291 119.336C264.451 120.956 262.581 122.106 260.061 122.096C253.811 122.096 247.561 122.096 241.311 122.096H238.701C237.931 123.266 238.201 124.376 238.201 125.426C238.201 149.926 238.201 174.422 238.201 198.916C238.201 207.916 235.131 215.486 228.261 221.416C225.257 224.091 221.651 226.002 217.751 226.986C217.041 227.156 216.491 227.306 216.221 227.986H52.7207Z"
                                  fill="#F6AC0F"
                                />
                                <path
                                  d="M143.757 71.1542H125.927C120.727 88.5742 115.217 106.294 109.017 123.774C102.817 141.254 96.2969 158.474 89.8269 175.984C87.7269 174.464 88.0069 171.984 87.2869 169.984C86.1669 166.884 85.6169 163.604 84.6169 160.454C83.6169 157.304 82.9969 153.984 82.2569 150.754C81.4469 146.954 80.3869 143.224 79.5569 139.444C78.8069 136.034 78.0669 132.614 77.2569 129.214C76.2569 125.034 75.5769 120.794 74.4569 116.624C73.4569 112.944 73.0469 109.104 72.1869 105.374C71.1869 101.104 70.7569 96.7242 69.4769 92.5142C68.9169 90.6442 68.9069 88.6642 68.4769 86.7342C67.3969 82.3942 67.1969 77.8242 66.1169 73.4842C65.0369 69.1442 64.8269 64.6242 63.4469 60.3442C62.9469 58.7942 63.6269 57.0142 62.5469 55.4042C64.0169 52.9042 66.4869 51.3442 68.6269 49.5142C73.8069 45.0742 79.1069 40.7842 84.3869 36.4642C92.8869 29.5342 101.387 22.6542 109.907 15.6842C114.387 11.9842 118.707 7.98423 123.307 4.33423C130.107 -1.07577 138.417 -1.66577 145.507 3.95423C155.067 11.5742 164.507 19.3442 173.927 27.1442C184.557 35.9842 195.107 44.8642 205.697 53.7242C206.117 54.0742 206.827 54.0642 206.897 54.3442C206.237 60.4042 204.997 65.9842 203.997 71.5642C202.897 77.3742 201.717 83.2042 200.547 89.0042C199.677 93.3042 198.787 97.6542 198.107 102.004C197.517 105.834 196.187 109.544 195.547 113.364C194.957 116.904 194.057 120.364 193.317 123.874C192.437 128.074 191.567 132.264 190.457 136.414C189.537 139.874 189.027 143.414 188.197 146.924C187.367 150.434 186.267 153.754 185.557 157.214C184.917 160.324 184.067 163.364 183.257 166.414C182.527 169.144 181.867 171.894 181.087 174.604C180.986 174.908 180.801 175.178 180.553 175.381C180.305 175.585 180.005 175.714 179.687 175.754C166.807 141.234 153.777 106.824 143.757 71.1542Z"
                                  fill="#135996"
                                />
                              </svg>
                            ) : (
                              <svg
                                width="27"
                                height="27"
                                viewBox="0 0 76 64"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M39.4626 0.724971C39.9932 1.01742 43.4817 3.97099 43.9247 4.35044C42.4136 4.43874 37.5954 1.77359 36.173 1.81146C35.8524 1.82 35.461 1.94637 35.2319 2.15806C33.9205 3.37007 32.69 4.67125 31.3511 5.85087C29.9974 7.0435 28.5367 8.11455 27.1468 9.26744C25.9205 10.2846 24.756 11.377 23.5111 12.3701C22.2714 13.359 20.8663 14.1625 19.7302 15.251C19.1487 15.8081 18.6262 16.8555 18.7175 17.6037C19.0355 20.2125 19.5917 22.7961 20.1327 25.3735C21.3653 31.2457 22.6024 37.1176 23.9266 42.9697C24.3112 44.6694 24.9969 46.3015 25.5446 47.9646C25.4152 48.4742 25.2857 48.9837 25.1563 49.4933C25.062 49.5053 24.9678 49.5173 24.8736 49.5293C24.3534 47.6415 23.7592 45.7703 23.3263 43.8627C21.8332 37.2837 20.3729 30.697 18.9503 24.1025C18.4097 21.5967 17.9594 19.0684 17.58 16.5337C17.5095 16.0631 17.8357 15.3531 18.215 15.0254C20.6135 12.9532 23.0744 10.9527 25.5225 8.9379C28.3543 6.60719 31.1869 4.27737 34.036 1.96771C34.6453 1.47375 35.3266 1.06802 35.9818 0.521504C36.5343 0.15905 37.0685 -0.0754383 37.7893 0.0222632C38.51 0.119965 39.1837 0.571261 39.4626 0.724971Z"
                                  fill="#B5B5C3"
                                />
                                <path
                                  d="M0.00612782 29.7681C3.98701 26.4927 7.9679 23.2173 12.106 19.8125C12.5527 21.9537 12.964 23.9573 13.3895 25.9579C14.2268 29.8957 14.9158 33.8733 15.9499 37.759C17.5741 43.8618 19.4585 49.895 21.1653 55.9766C21.422 56.8914 21.8469 57.1275 22.6935 57.0638C23.4327 57.0082 24.1793 57.0522 24.9226 57.0522C27.1742 57.0522 27.1291 57.0359 27.9104 54.8688C31.0012 46.2949 34.1224 37.7319 37.2365 29.1663C37.2975 28.9985 37.4097 28.8492 37.5995 28.5101C38.1986 30.2721 38.7225 31.918 39.3154 33.5386C42.0438 40.9957 44.7982 48.4433 47.5231 55.9016C47.8506 56.7978 48.3571 57.1892 49.3444 57.0722C50.169 56.9746 51.0292 56.9531 51.8472 57.075C53.1311 57.2664 53.6788 56.7355 54.0689 55.5459C56.9586 46.7343 59.3438 37.7953 61.098 28.6873C61.6541 25.7997 62.2941 22.9284 62.9325 19.8734C64.0489 20.7491 65.1509 21.5882 66.2252 22.4616C68.7742 24.5339 71.2937 26.6428 73.8581 28.6957C75.105 29.6939 75.6779 31.1369 75.2145 32.4568C74.6843 33.9668 73.5629 34.7359 71.8655 34.7405C70.1032 34.7454 68.3409 34.7416 66.3806 34.7416C66.3806 35.2784 66.3806 35.7669 66.3806 36.2554C66.3806 42.9916 66.3518 49.728 66.3932 56.4639C66.4127 59.6375 64.9224 61.811 62.2376 63.3046C61.9672 63.455 61.7327 63.67 61.5605 63.9396C45.6445 64.0243 29.6494 64.0243 13.5218 63.9213C13.2014 63.6522 13.0368 63.4437 12.8222 63.3263C10.0039 61.7853 8.62414 59.4392 8.64133 56.205C8.67667 49.56 8.65195 42.9145 8.65195 36.2693C8.65195 35.7793 8.65195 35.2894 8.65195 34.7416C6.79489 34.7416 5.12417 34.6898 3.45825 34.757C1.95277 34.8177 0.819752 34.2714 0.000496588 32.9109C-0.00137387 31.7892 0.00237685 30.7786 0.00612782 29.7681Z"
                                  fill="#DBDBE9"
                                />
                                <path
                                  d="M25.4159 48.3693C24.7788 46.7372 24.085 45.0806 23.6958 43.3552C22.3558 37.4149 21.1039 31.4545 19.8567 25.4938C19.3092 22.8775 18.7464 20.255 18.4246 17.6069C18.3323 16.8474 18.8609 15.7842 19.4494 15.2187C20.5991 14.1138 22.0208 13.2981 23.2753 12.2944C24.535 11.2863 25.7134 10.1774 26.9543 9.14496C28.3608 7.9747 29.8388 6.8875 31.2086 5.67689C32.5635 4.47949 33.8086 3.1587 35.1356 1.92842C35.3674 1.71354 35.7635 1.58526 36.088 1.57659C37.5273 1.53816 39.4234 1.97835 40.997 1.97402C44.4634 4.71405 47.3361 7.04313 50.6767 9.77704C52.4873 11.2587 54.2449 12.8221 56.1651 14.1476C57.7925 15.2711 57.6984 16.6688 57.3529 18.2569C55.2152 28.0805 53.09 37.907 50.9377 47.7275C50.767 48.5068 50.4226 49.2481 49.8143 50.0155C46.9003 41.5873 43.9822 33.1606 41.0785 24.7289C40.7809 23.8646 40.5869 22.9652 40.3147 22.0915C39.7027 20.127 39.4981 20.0201 37.3294 20.2012C36.6433 20.2585 35.9484 20.2101 35.5263 20.2101C32.1375 29.7074 28.8181 39.0104 25.4159 48.3693Z"
                                  fill="#B5B5C3"
                                />
                              </svg>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                </div>

                <div
                  className="col-xl-3 col-md-6 mb-4"
                  onMouseEnter={() => setId(2)}
                  onMouseLeave={() => setId("")}
                >
                  <NavLink className="" to="/vattenfall">
                    <div className="card rounded-3 border-5 border-bottom shadow h-100 py-2">
                      <div className="card-body cursor-pointer">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="text-xs font-weight-bold text-sjma text-uppercase mb-1">
                              VATENFALL LEADS
                            </div>
                            <div className="h5 mb-0 font-weight-bold text-danger-800">
                              <CountUp
                                end={dashboardCount.vattenfallCount || 0}
                              />
                            </div>
                          </div>
                          <div className="col-auto">
                            {!(id == "2") ? (
                              <svg
                                width="27"
                                height="27"
                                viewBox="0 0 74 75"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M37 0C16.5654 0 0 17.0131 0 38H74C74 17.0131 57.4346 0 37 0Z"
                                  fill="#DBDBE9"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M37 75C16.5654 75 0 58.4346 0 38H74C74 58.4346 57.4346 75 37 75Z"
                                  fill="#B5B5C3"
                                />
                              </svg>
                            ) : (
                              <svg
                                width="27"
                                height="27"
                                viewBox="0 0 228 230"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M113.689 0C50.9003 0 0 51.4871 0 115H227.379C227.379 51.4871 176.478 0 113.689 0Z"
                                  fill="#F7D307"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M113.689 230C50.9003 230 0 178.513 0 115H227.379C227.379 178.513 176.478 230 113.689 230Z"
                                  fill="#1E6EB0"
                                />
                              </svg>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                </div>

                <div
                  className="col-xl-3 col-md-6 mb-4"
                  onMouseEnter={() => setId(10)}
                  onMouseLeave={() => setId("")}
                >
                  <NavLink className="" to="/vattenfallmesse">
                    <div className="card rounded-3 border-5 border-bottom shadow h-100 py-2">
                      <div className="card-body cursor-pointer">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="text-xs font-weight-bold text-sjma text-uppercase mb-1">
                              Vattenfall Messe Leads
                            </div>
                            <div className="h5 mb-0 font-weight-bold text-danger-800">
                              <CountUp
                                end={dashboardCount.vattenfallMesseCount || 0}
                              />
                            </div>
                          </div>
                          <div className="col-auto">
                            {!(id == "10") ? (
                              <svg
                                width="27"
                                height="27"
                                viewBox="0 0 74 75"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M37 0C16.5654 0 0 17.0131 0 38H74C74 17.0131 57.4346 0 37 0Z"
                                  fill="#DBDBE9"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M37 75C16.5654 75 0 58.4346 0 38H74C74 58.4346 57.4346 75 37 75Z"
                                  fill="#B5B5C3"
                                />
                              </svg>
                            ) : (
                              <svg
                                width="27"
                                height="27"
                                viewBox="0 0 228 230"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M113.689 0C50.9003 0 0 51.4871 0 115H227.379C227.379 51.4871 176.478 0 113.689 0Z"
                                  fill="#F7D307"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M113.689 230C50.9003 230 0 178.513 0 115H227.379C227.379 178.513 176.478 230 113.689 230Z"
                                  fill="#1E6EB0"
                                />
                              </svg>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                </div>
                <div
                  className="col-xl-3 col-md-6 mb-4"
                  onMouseEnter={() => setId(21)}
                  onMouseLeave={() => setId("")}
                >
                  <NavLink className="" to="/vattenfallpromo">
                    <div className="card rounded-3 border-5 border-bottom shadow h-100 py-2">
                      <div className="card-body cursor-pointer">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="text-xs font-weight-bold text-sjma text-uppercase mb-1">
                              Vattenfall PROMO Leads
                            </div>
                            <div className="h5 mb-0 font-weight-bold text-danger-800">
                              <CountUp
                                end={dashboardCount.vattenfallPromoCount || 0}
                              />
                            </div>
                          </div>
                          <div className="col-auto">
                            {!(id == "21") ? (
                              <svg
                                width="27"
                                height="27"
                                viewBox="0 0 74 75"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M37 0C16.5654 0 0 17.0131 0 38H74C74 17.0131 57.4346 0 37 0Z"
                                  fill="#DBDBE9"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M37 75C16.5654 75 0 58.4346 0 38H74C74 58.4346 57.4346 75 37 75Z"
                                  fill="#B5B5C3"
                                />
                              </svg>
                            ) : (
                              <svg
                                width="27"
                                height="27"
                                viewBox="0 0 228 230"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M113.689 0C50.9003 0 0 51.4871 0 115H227.379C227.379 51.4871 176.478 0 113.689 0Z"
                                  fill="#F7D307"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M113.689 230C50.9003 230 0 178.513 0 115H227.379C227.379 178.513 176.478 230 113.689 230Z"
                                  fill="#1E6EB0"
                                />
                              </svg>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                </div>
                <div className="col-xl-3 col-md-6 mb-4">
                  <NavLink className="" to="/funnel2">
                    <div className="card rounded-3 border-5 border-bottom shadow h-100 py-2">
                      <div className="card-body cursor-pointer">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="text-xs font-weight-bold text-sjma text-uppercase mb-1">
                              Google Ads & Vergleich
                            </div>
                            <div className="h5 mb-0 font-weight-bold text-danger-800">
                              <CountUp end={dashboardCount.staper2Count || 0} />
                            </div>
                          </div>
                          <div className="col-auto">
                            <i
                              class="fa-solid fa-chart-simple"
                              style={{ fontSize: "25px" }}
                            ></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                </div>

                <div className="col-xl-3 col-md-6 mb-4">
                  <NavLink className="" to="/anfrange">
                    <div className="card rounded-3 border-5 border-bottom shadow h-100 py-2">
                      <div className="card-body cursor-pointer">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="text-xs font-weight-bold text-sjma text-uppercase mb-1">
                              Anfrage (Website)
                            </div>
                            <div className="h5 mb-0 font-weight-bold text-danger-800">
                              <CountUp end={dashboardCount.contectCount || 0} />
                            </div>
                          </div>
                          <div className="col-auto">
                            <i className="fas  fa-users fa-2x text-danger-300"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                </div>

                <div className="col-xl-3 col-md-6 mb-4">
                  <NavLink className="" to="/news">
                    <div className="card rounded-3 border-5 border-bottom shadow h-100 py-2">
                      <div className="card-body cursor-pointer">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="text-xs font-weight-bold text-sjma text-uppercase mb-1">
                              Newsletter (Website)
                            </div>
                            <div className="h5 mb-0 font-weight-bold text-danger-800">
                              <CountUp
                                end={dashboardCount.newsLaterCount || 0}
                              />
                            </div>
                          </div>
                          <div className="col-auto dashboardlogo">
                            <i
                              width="30px"
                              height="35px"
                              class="fa fa-newspaper-o fa-2x text-danger-300"
                              aria-hidden="true"
                            ></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                </div>

                <div className="col-xl-3 col-md-6 mb-4">
                  <NavLink className="" to="/kontakt">
                    <div className="card rounded-3 border-5 border-bottom shadow h-100 py-2">
                      <div className="card-body cursor-pointer">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="text-xs font-weight-bold text-sjma text-uppercase mb-1">
                              Kontakt (Website)
                            </div>
                            <div className="h5 mb-0 font-weight-bold text-danger-800">
                              <CountUp end={dashboardCount.inquiryCount || 0} />
                            </div>
                          </div>
                          <div className="col-auto">
                            <i class="fa-solid fa-address-book fa-2x text-danger-300"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                </div>

                <div className="col-xl-3 col-md-6 mb-4">
                  <NavLink className="" to="/credential">
                    <div className="card rounded-3 border-5 border-bottom shadow h-100 py-2">
                      <div className="card-body cursor-pointer">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="text-xs font-weight-bold text-sjma text-uppercase mb-1">
                              Vertriebspartner
                            </div>
                            <div className="h5 mb-0 font-weight-bold text-danger-800">
                              <CountUp end={dashboardCount.userCount || 0} />
                            </div>
                          </div>
                          <div className="col-auto">
                            <i
                              class="fa-solid fa-people-group"
                              style={{ fontSize: "25px" }}
                            ></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                </div>

                <div className="col-xl-3 col-md-6 mb-4">
                  <NavLink className="" to="/project">
                    <div className="card rounded-3 border-5 border-bottom shadow h-100 py-2">
                      <div className="card-body cursor-pointer">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="text-xs font-weight-bold text-sjma text-uppercase mb-1">
                              Projekte
                            </div>
                            <div className="h5 mb-0 font-weight-bold text-danger-800">
                              <CountUp end={dashboardCount.projectCount || 0} />
                            </div>
                          </div>
                          <div className="col-auto">
                            <i class="fa fa-project-diagram fa-2x text-danger-300"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
          <div className="col-lg-12 col-xxl-12 p-0">
          <div className="row">
            <div
              className="col-xl-3 col-md-6 mb-4"
              onMouseEnter={() => setId(2)}
              onMouseLeave={() => setId("")}
            >
              <NavLink className="" to="/vattenfall">
                <div className="card rounded-3 border-5 border-bottom shadow h-100 py-2">
                  <div className="card-body cursor-pointer">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-sjma text-uppercase mb-1">
                          VATENFALL LEADS
                        </div>
                        <div className="h5 mb-0 font-weight-bold text-danger-800">
                          <CountUp end={dashboardCount.vattenfallCount || 0} />
                        </div>
                      </div>
                      <div className="col-auto">
                        {!(id == "2") ? (
                          <svg
                            width="27"
                            height="27"
                            viewBox="0 0 74 75"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M37 0C16.5654 0 0 17.0131 0 38H74C74 17.0131 57.4346 0 37 0Z"
                              fill="#DBDBE9"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M37 75C16.5654 75 0 58.4346 0 38H74C74 58.4346 57.4346 75 37 75Z"
                              fill="#B5B5C3"
                            />
                          </svg>
                        ) : (
                          <svg
                            width="27"
                            height="27"
                            viewBox="0 0 228 230"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M113.689 0C50.9003 0 0 51.4871 0 115H227.379C227.379 51.4871 176.478 0 113.689 0Z"
                              fill="#F7D307"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M113.689 230C50.9003 230 0 178.513 0 115H227.379C227.379 178.513 176.478 230 113.689 230Z"
                              fill="#1E6EB0"
                            />
                          </svg>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </NavLink>
            </div>

            <div
              className="col-xl-3 col-md-6 mb-4"
              onMouseEnter={() => setId(10)}
              onMouseLeave={() => setId("")}
            >
              <NavLink className="" to="/vattenfallmesse">
                <div className="card rounded-3 border-5 border-bottom shadow h-100 py-2">
                  <div className="card-body cursor-pointer">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-sjma text-uppercase mb-1">
                          Vattenfall Messe Leads
                        </div>
                        <div className="h5 mb-0 font-weight-bold text-danger-800">
                          <CountUp
                            end={dashboardCount.vattenfallMesseCount || 0}
                          />
                        </div>
                      </div>
                      <div className="col-auto">
                        {!(id == "10") ? (
                          <svg
                            width="27"
                            height="27"
                            viewBox="0 0 74 75"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M37 0C16.5654 0 0 17.0131 0 38H74C74 17.0131 57.4346 0 37 0Z"
                              fill="#DBDBE9"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M37 75C16.5654 75 0 58.4346 0 38H74C74 58.4346 57.4346 75 37 75Z"
                              fill="#B5B5C3"
                            />
                          </svg>
                        ) : (
                          <svg
                            width="27"
                            height="27"
                            viewBox="0 0 228 230"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M113.689 0C50.9003 0 0 51.4871 0 115H227.379C227.379 51.4871 176.478 0 113.689 0Z"
                              fill="#F7D307"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M113.689 230C50.9003 230 0 178.513 0 115H227.379C227.379 178.513 176.478 230 113.689 230Z"
                              fill="#1E6EB0"
                            />
                          </svg>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </NavLink>
            </div>
            <div
              className="col-xl-3 col-md-6 mb-4"
              onMouseEnter={() => setId(21)}
              onMouseLeave={() => setId("")}
            >
              <NavLink className="" to="/vattenfallpromo">
                <div className="card rounded-3 border-5 border-bottom shadow h-100 py-2">
                  <div className="card-body cursor-pointer">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-sjma text-uppercase mb-1">
                          Vattenfall PROMO Leads
                        </div>
                        <div className="h5 mb-0 font-weight-bold text-danger-800">
                          <CountUp
                            end={dashboardCount.vattenfallPromoCount || 0}
                          />
                        </div>
                      </div>
                      <div className="col-auto">
                        {!(id == "21") ? (
                          <svg
                            width="27"
                            height="27"
                            viewBox="0 0 74 75"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M37 0C16.5654 0 0 17.0131 0 38H74C74 17.0131 57.4346 0 37 0Z"
                              fill="#DBDBE9"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M37 75C16.5654 75 0 58.4346 0 38H74C74 58.4346 57.4346 75 37 75Z"
                              fill="#B5B5C3"
                            />
                          </svg>
                        ) : (
                          <svg
                            width="27"
                            height="27"
                            viewBox="0 0 228 230"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M113.689 0C50.9003 0 0 51.4871 0 115H227.379C227.379 51.4871 176.478 0 113.689 0Z"
                              fill="#F7D307"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M113.689 230C50.9003 230 0 178.513 0 115H227.379C227.379 178.513 176.478 230 113.689 230Z"
                              fill="#1E6EB0"
                            />
                          </svg>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </NavLink>
            </div>
            </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
